//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TriangleCustomRate from '$resources/images/triangle-custom-rate.png';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    selected: Boolean,
    offer: Object,
    hiredPower: String,
    variant: {
      type: String,
      validator: v => ['common', 'without-wattage'].includes(v),
      default: 'common'
    },
    steps: Object
  },
  data: () => ({
    TriangleCustomRate
  }),
  computed: {
    ratesDescription() {
      switch (this.offer.idTarifaPersonalizada) {
        case '96':
        case '103':
        case '104':
        case '107':
        case '122':
        case '123':
        case '125':
        case '126':
        case '127':
        case '128':
        case '129':
        case '130':
        case '131':
        case '132':
        case '133':
        case '134':
        case '135':
          return [
            'Precios válidos para 12 meses dentro del territorio peninsular.',
            'Se mantendrán los precios siempre que los volúmenes consumidos estén en +/-10% del consumo anual contratado. En caso de variaciones superiores MET ENERGIA ESPAÑA se reserva el derecho de ajustar el precio.',
            'Estos precios no incluyen impuestos, ni el mecanismo de ajuste establecido en el RD-Ley 10/2022.'
          ];
        case '169':
          return [
            'Si quieres una tarifa de luz  a precio de coste, elige nuestro precio indexado totalmente transparente',
            'Ahorra aprovechando las franjas horarias.',
            'Ahorra con MET pagando la energía a precio de coste.'
          ];
        case '97':
          return [
            'Si quieres tranquilidad en los precios independientemente de cuando consumas, elige nuestro precio fijo 24 horas.',
            'Ahorra con MET y olvídate de los horarios.'
          ];

        case '95':
        case '118':
        case '168':
        case '188':
          return [
            'Si quieres una tarifa de luz  a precio de coste, elige nuestro precio indexado totalmente transparente',
            'Ahorra aprovechando las franjas horarias.',
            'Ahorra con MET pagando la energía a precio de coste.'
          ];
        case '208':
          return [
            'Si dispones de instalación fotovoltaica en tu domicilio, quieres sacarle el mayor provecho a tus excedentes y compensarlos en tu factura de consumo, elige nuestra Batería Virtual',
            'Podrás reducir tu factura a 0€.',
            'Trasladar tus beneficios a otro suministro del que seas titular.'
          ];
        default:
          return [];
      }
    },
    tarifaNameFormated() {
      if (this.offer.aliasTarifa) {
        return this.offer.aliasTarifa;
      }

      return this.offer.nombreTarifa;
    },
    priceMontlyClassName() {
      return {
        alone: this.offer.ahorroMensual === null,
        small: this.offer.precioMensual
      };
    },
    savingsMontlyClassName() {
      return {
        small: String(this.offer.ahorroMensual.toFixed(2)).length > 5
      };
    },
    computedFeeEnergia() {
      const tempEnergia = Object.values(this.offer.feeEnergiaSelected);
      return tempEnergia.some(fee => fee !== null);
    },
    computedTextFeeEnergia() {
      const tempEnergia = Object.values(this.offer.feeEnergiaSelected);
      return tempEnergia
        .map((fee, index) => {
          if (fee !== null) {
            return `P${index + 1}: ${fee}`;
          }
          return null;
        })
        .filter(fee => fee !== null)
        .join(' ');
    },
    computedFeeAutoconsumo() {
      const tempEnergia = Object.values(this.offer.feeAutoconsumoSelected);
      return tempEnergia.some(fee => fee !== null);
    },
    computedTextAutoconsumo() {
      const tempAutoconsumo = Object.values(this.offer.feeAutoconsumoSelected);
      return tempAutoconsumo
        .map((fee, index) => {
          if (fee !== null) {
            return `P${index + 1}: ${fee}`;
          }
          return null;
        })
        .filter(fee => fee !== null)
        .join(' ');
    },
    computedFeePotencia() {
      const tempEnergia = Object.values(this.offer.feePotenciaSelected);
      return tempEnergia.some(fee => fee !== null);
    },
    computedTextFeePotencia() {
      const tempPotencia = Object.values(this.offer.feePotenciaSelected);
      return tempPotencia
        .map((fee, index) => {
          if (fee !== null) {
            return `P${index + 1}: ${fee}`;
          }
          return null;
        })
        .filter(fee => fee !== null)
        .join(' ');
    }
  }
};
